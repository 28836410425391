































































import UserProfileApi from "@/includes/Api/UserProfile.api";
import { UserCommunicationStatByPeriodRes, UserProfile } from "@/includes/types/UserProfile/types";
import PiTableHeader from "@/components/chat/PiTableHeader.vue";
import UserToxicityValues from "@/components/statistic/components/UserToxicityValues.vue";

import UserMainInfo from "piramis-base-components/src/components/UserMainInfo.vue";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";
import EmptyData from "piramis-base-components/src/components/EmptyData/EmptyData.vue";

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    UserToxicityValues,
    PiTableHeader,
    UserMainInfo,
    Icon,
    EmptyData,
  }
})
export default class UserCommunication extends Vue {
  @Prop() user!: UserProfile

  @Prop() period!: { from: String, to: string }

  @Prop({ type: Boolean, default: false }) needUpdate!: boolean

  statLoading = false

  @Watch('needUpdate')
  onChange(state: boolean) {
    if (state) {
      this.loadCommunicationStatistic()
    }
  }

  get columns() {
    return [
      {
        title: this.$t('stat_users_data_table_col_user'),
        key: 'user-info',
        width: 270,
        scopedSlots: { customRender: 'user-info' },
      },
      {
        title: this.$t('user_profile_communication_table_col_give', { name: this.user.user_name }).toString(),
        dataIndex: 'Give', // vidano
        scopedSlots: { customRender: 'orientation' },
      },
      {
        title: this.$t('user_profile_communication_table_col_take', { name: this.user.user_name }).toString(),
        dataIndex: 'Take', // polycheno
        scopedSlots: { customRender: 'orientation' },
      }
    ]
  }

  pagination = {
    limit: 10,
    page: 1,
  }

  statistics: UserCommunicationStatByPeriodRes | null = null

  //https://1x.antdv.com/components/table/#API api here
  handleChange(pagination: any, _: any) {
    const { page, limit } = this.pagination

    if (pagination.current !== page) {
      this.pagination.page = pagination.current

      this.loadCommunicationStatistic()

      return
    }

    if (limit !== pagination.pageSize) {
      this.pagination.limit = pagination.pageSize

      this.loadCommunicationStatistic()

      return
    }
  }

  loadCommunicationStatistic() {
    this.statLoading = true

    UserProfileApi.getCommunicationStatisticsByPeriod({
      chat_id: this.$store.getters.chatId,
      user_id: this.user.user_id,
      from: this.period.from,
      to: this.period.to,
      limit: this.pagination.limit,
      offset: this.pagination.page === 1 ? 0 : (this.pagination.page - 1) * this.pagination.limit
    })
      .then(({ data }) => {
        this.statistics = data
      })
      .finally(() => {
        this.statLoading = false
      })
  }

  created() {
    this.loadCommunicationStatistic()
  }
}
