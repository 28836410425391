import { render, staticRenderFns } from "./UserToxicityValues.vue?vue&type=template&id=9f3b9242&scoped=true&"
import script from "./UserToxicityValues.vue?vue&type=script&lang=ts&"
export * from "./UserToxicityValues.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f3b9242",
  null
  
)

export default component.exports