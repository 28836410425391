











































import FormsApi from "@/includes/Api/Forms.api";
import FormAnswersItem from "@/components/questionnaire/components/FormAnswersItem.vue";
import { GetUserFormAnswersRes } from "@/includes/logic/Forms/types";
import { errorNotification } from "@/includes/NotificationService";

import DrawerWidthMixin from "piramis-base-components/src/logic/helpers/DrawerWidthMixin";

import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    FormAnswersItem
  }
})
export default class UserProfileForms extends Mixins(DrawerWidthMixin) {
  forms: GetUserFormAnswersRes['forms'] | null = null

  isDrawerOpen = false

  created() {
    this.initializeSidebarWidth(800)

    FormsApi.getUserFormAnswers('tg', {
      chat_id: this.$store.getters.chatId,
      user_id: +this.$route.params['userId']
    })
      .then(({ data }) => {
        this.forms = data.forms
      })
      .catch((error) => {
        errorNotification(error)

        this.forms = []
      })
  }
}
