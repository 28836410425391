





















































































































































import { UserProfile } from '@/includes/types/UserProfile/types'
import { ModuleManagerState } from "@/includes/logic/Modules/ModulesManager";
import { ModuleTypesEnum } from "@/includes/logic/Modules/types";
import RanksHandlerModule from "@/includes/logic/Modules/modules/modules/RanksHandlerModule/RanksHandlerModule";
import TelegramProfileButton from "@/components/TelegramProfileButton.vue";
import UserProfileAchievements from "./UserProfileAchievements.vue";
import { ChatConfig } from '@/includes/types/Chat/types';
import UserProfileForms from "@/components/UserProfile/UserProfileForms.vue";

import ClipboardCopyMixin from 'piramis-base-components/src/logic/helpers/ClipboardCopyMixin'
import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import { tgLoginLink } from "piramis-base-components/src/shared/utils/tgLoginHelpers";

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import moment from 'moment'

const LAST_MESSAGE_SLOT = 'LastMessage'
const USER_RANK_SLOT = 'UserRank'

@Component({
  methods: {
    tgLoginLink
  },
  components: {
    UserProfileForms,
    UserProfileAchievements,
    TelegramProfileButton,
    Icon,
    UserMainInfo,
  },
  data() {
    return {
      LAST_MESSAGE_SLOT,
      USER_RANK_SLOT
    }
  }
})
export default class UserProfileInfo extends Mixins(ClipboardCopyMixin) {
  @Prop() user!: UserProfile

  @Prop() achievements!: ChatConfig['common_config']['achievements']

  @Emit()
  removeUserAchievement(id: number) {
    return id
  }

  @Emit()
  addUserAchievement(id: number) {
    return id
  }

  messagesData = [
    {
      title: this.$t('user_info_message_count').toString(),
      value: this.user?.message_count !== undefined ? this.user.message_count : '-',
      icon: {
        name: "message",
      }
    },
    {
      title: this.$t('user_info_days_with_messages').toString(),
      value: this.user.days_with_messages,
      icon: {
        name: "edit_calendar",
      },
      subtitle: {
        title: this.$t('user_info_first_message_time').toString(),
        value: this.user?.first_message !== undefined ? moment(this.user.first_message).format('LL') : '-'
      }
    },
    {
      title: this.$t('user_info_last_message_time').toString(),
      icon: {
        name: "last_page",
      },
      value: this.user?.last_message_time !== undefined ? moment(this.user.last_message_time).format('LLL') : '-',
      subtitle: {
        render: LAST_MESSAGE_SLOT,
        title: this.$t('user_info_last_message_watch').toString(),
        value: this.user.last_message_link
      }
    },
    ...this.user.ban_level ? [
      {
        title: this.$t('user_info_ban_level').toString(),
        icon: {
          name: "block",
        },
        value: this.user.ban_level,
        subtitle: {
          title: this.$t('user_info_last_ban_time').toString(),
          value: this.user?.last_ban_time !== undefined ? moment(this.user.last_ban_time).format('LL') : '-'
        }
      },
    ] : [],
    ...this.user.warn_count ? [
      {
        title: this.$t('user_info_warn_limit_count').toString(),
        icon: {
          name: "warning_amber",
        },
        value: this.user.warn_count,
        subtitle: {
          title: this.$t('user_info_warn_last_time').toString(),
          value: this.user?.warn_last_time !== undefined ? moment(this.user.warn_last_time).format('LL') : '-'
        }
      }
    ] : [],
  ]

  data = [
    {
      title: this.$t('user_info_days_in_chat').toString(),
      value: this.user.days_in_chat ?? '-',
      icon: {
        name: "calendar_today",
      },
      subtitle: {
        title: this.$t('user_info_enter_time').toString(),
        value: this.user?.first_enter !== undefined ? moment(this.user.first_enter).format('LL') : '-'
      }
    },
    ...this.userRank ? [ {
      title: this.$t('user_info_rank').toString(),
      value: this.userRank.title,
      icon: {
        name: "military_tech",
      },
      subtitle: {
        render: USER_RANK_SLOT,
      }
    } ] : [],

  ]

  get apValue() {
    return this.user?.action_points ?? 0
  }

  get reputationValue() {
    return this.user?.reputation ?? 0
  }

  get xpValue() {
    return this.user?.xp ?? 0
  }

  get userRank() {
    if (ModuleManagerState) {
      const rankModule = ModuleManagerState.getModuleByCriteria('type', ModuleTypesEnum.RanksHandlerModule) as RanksHandlerModule | undefined

      if (rankModule) {
        const ranks = rankModule.params.user_ranks

        if (ranks && ranks.length) {
          return ranks.find((_, index: number) => index === this.user.rank)
        }
      }
    }

    return null
  }
}
