var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-communication"},[_c('a-table',{attrs:{"loading":_vm.statLoading,"bordered":"","data-source":_vm.statistics ? _vm.statistics.data : [],"columns":_vm.columns,"locale":{
      emptyText: _vm.$t('user_profile_communication_empty')
    },"pagination":{
      'total': _vm.statistics ? _vm.statistics.count : 1,
      'show-size-changer': true,
      'show-quick-jumper': true,
      'page-size-options': [ '10', '20', '30' ]
    }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"user-info",fn:function(text, record){return _c('user-main-info',{attrs:{"name":record.name,"login":record.login,"avatar":record.avatar}})}},{key:"orientation",fn:function(text, record){return _c('div',{staticClass:"flex flex-col gap-1"},[(text.messages)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('user_profile_communication_table_messages_value'))+" "),_c('b',[_vm._v(_vm._s(text.messages.count))])]):_vm._e(),(text.messages)?_c('user-toxicity-values',{attrs:{"user-toxicity":text.messages}}):_vm._e(),(text.reactions)?_c('div',{staticClass:"flex gap-1 flex-wrap"},_vm._l((text.reactions),function(reaction){return _c('a-tag',{key:reaction.id,staticClass:"rounded-full mr-0",attrs:{"color":"blue"}},[_vm._v(" "+_vm._s(reaction.value)+" "+_vm._s(reaction.count)+" ")])}),1):_vm._e()],1)}}])},[_c('pi-table-header',{attrs:{"slot":"title","table-title":_vm.$t('user_info_communication_table_title'),"has-search":false},slot:"title"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }