











































































































































import { UserProfile, UsersProfileStat } from "@/includes/types/UserProfile/types";
import UserProfileApi from "@/includes/Api/UserProfile.api";
import { errorNotification } from "@/includes/NotificationService";
import StaticDataApi from "@/includes/Api/StaticData.api";
import AchievementsApi from "@/includes/Api/Achievements.api";
import { makeMapAchievements } from "@/includes/logic/achievements";
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";
import UserProfileInfo from "@/components/UserProfile/UserProfileInfo.vue";
import UserReferralsTable from "@/components/UserProfile/UserReferralsTable.vue";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import { ADVANCED_TAG } from "@/includes/constants";
import SetPeriod from "@/views/chat/statistics/components/mainStatistics/setPeriod.vue";
import MetricCard from "@/components/CommunityDashboard/components/MetricCard.vue";
import UserToxicityValues from "@/components/statistic/components/UserToxicityValues.vue";
import UserProfileWeekDay from "@/components/UserProfile/charts/UserProfileWeekDay.vue";
import UserProfileHour from "@/components/UserProfile/charts/UserProfileHour.vue";
import UserProfileFullChart from "@/components/UserProfile/charts/UserProfileFullChart.vue";
import UserCommunication from "@/components/UserProfile/UserCommunication.vue";
import UserProfileJournalTimeline from "@/components/UserProfile/UserProfileJournalTimeline.vue";

import PageTitle from "piramis-base-components/src/components/PageTitle.vue";

import Vue from "vue";
import { Component, Ref, Watch } from "vue-property-decorator";
import moment from "moment";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

@Component({
  data() {
    return {
      ADVANCED_TAG,
      SitePermissionEnum
    }
  },
  components: {
    UserProfileJournalTimeline,
    UserCommunication,
    UserProfileFullChart,
    UserProfileHour,
    UserProfileWeekDay,
    UserToxicityValues,
    MetricCard,
    SetPeriod,
    NotAvailableOptionsOverlay,
    UserReferralsTable,
    UserProfileInfo,
    PermissionsBanner,
    PageTitle,
    ConfigField
  },
})
export default class UserInfo extends Vue {

  referralsDataGenTime = null

  referralData = null

  userData: UserProfile | null = null

  usersProfileStat: UsersProfileStat | null = null

  isUsersStatLoading = false

  isUsersDataLoading = false

  statisticsTriggered = false

  period = {
    from: moment().add(-1, 'month').format('YYYY-MM-DD'),
    to:  moment().format('YYYY-MM-DD')
  }

  @Ref('commStat') commStatBlock!: HTMLDivElement | undefined

  @Watch('commStatBlock')
  onChange(el: HTMLDivElement | undefined) {
    if (el) {
      const io = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            if (!this.statisticsTriggered) {
              this.statisticsTriggered = true
            }
          }
        });
      });

      io.observe(el)
    }
  }

  @Watch('$route.params.userId')
  onUserIdParamChange() {
    this.userData = this.referralData = null

    this.getStat()
  }

  get userId() {
    return Number.parseInt(this.$route.params.userId)
  }

  get userToxicity() {
    return this?.usersProfileStat?.toxicity?.[this.userData?.user_id ?? -1]
  }

  get hasAtLeastOneToxicityMetric() {
    if (this.userToxicity) {
      return this.userToxicity?.['profanity'] > 1 || this.userToxicity?.['toxicity'] > 1 || this.userToxicity?.['severeToxicity'] > 1
    }

    return false
  }

  get userStatData() {
    return this.usersProfileStat?.data[0]
  }

  get canStatistics() {
    return this.$store.getters.canStatistics
  }

  getProfileInfo() {
    this.isUsersDataLoading = true

    UserProfileApi.getUsersProfileInfo(
      this.$store.getters.chatId,
      [ this.userId ],
    )
      .then(({ data }) => {
        this.userData = data.users[0]
      })
      .catch(errorNotification)
      .finally(() => {
        this.isUsersDataLoading = false
      })
  }

  getReferrals() {
    if (this.$store.getters.isChatAtLeastAdvanced) {
      StaticDataApi.getChatReferrals(this.$store.getters.chatId)
        .then(({ data }) => {
          this.referralsDataGenTime = data.gentime
          this.referralData = data.users.filter((user: any) => user.user_id === this.userId)[0]
        })
        .catch(errorNotification)
    }
  }

  getUsersProfileStat() {
    this.isUsersStatLoading = true

    UserProfileApi.getUsersProfileStat(
      this.$store.getters.chatId,
      [ this.userId ],
      this.period.from,
      this.period.to
    )
      .then(({ data }) => {
        this.usersProfileStat = data
      })
      .catch(errorNotification)
      .finally(() => {
        this.isUsersStatLoading = false
      })
  }

  removeUserAchievement(id: number) {
    if (this.userData) {
      this.isUsersDataLoading = true

      AchievementsApi.removeUserAchievement(this.$store.getters.chatId, this.userData.user_id, id)
        .then(({ data }) => {
          if (this.userData && this.usersProfileStat) {
            this.usersProfileStat.achievements[this.userData.user_id] = makeMapAchievements(data.achievements)
          }
        })
        .catch(errorNotification)
        .finally(() => this.isUsersDataLoading = false)
    }
  }

  addUserAchievement(id: number) {
    if (this.userData) {
      this.isUsersDataLoading = true

      AchievementsApi.addUserAchievement(this.$store.getters.chatId, this.userData.user_id, id)
        .then(({ data }) => {
          if (this.userData && this.usersProfileStat) {
            this.usersProfileStat.achievements[this.userData.user_id] = makeMapAchievements(data.achievements)
          }
        })
        .catch(errorNotification)
        .finally(() => this.isUsersDataLoading = false)
    }
  }

  getStat() {
    if (this.canStatistics) {
      Promise.all([
        this.getProfileInfo(),
        this.getReferrals(),
        this.getUsersProfileStat(),
      ])
        .then(res => res)
    }
  }

  created() {
    this.getStat()
  }
}
