











































































import { ChatConfig } from '@/includes/types/Chat/types';
import { makeListAchievements, sortAchievements, getAchievementTitle, AchievementsMixin } from "@/includes/logic/achievements";
import AchievementEditModal from "@/components/Achievements/AchievementEditModal.vue";

import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue';

import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    AchievementEditModal,
    Accordion
  },
  methods: {
    getAchievementTitle
  }
})
export default class UserProfileAchievements extends Mixins(AchievementsMixin) {
  @Prop() userAchievements!: Record<number, string>

  @Prop() achievements!: ChatConfig['common_config']['achievements']

  @Emit()
  removeUserAchievement(id: number) {
    return id
  }

  @Emit()
  addUserAchievement(id: number) {
    return id
  }

  get sortedUserAchievements() {
    return sortAchievements(makeListAchievements(this.userAchievements ?? {}), 'id')
  }

  get userAchievementIds() {
    return this.userAchievements ? Object.keys(this.userAchievements) : []
  }

  get filteredAchievements() {
    return sortAchievements(this.achievements.filter(a => !this.userAchievementIds.includes(a.id.toString())), 'id')
  }
}
