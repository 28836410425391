























import { UserToxicityFields } from "@/includes/types/UserProfile/types";

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({})
export default class UserToxicityValues extends Vue {
  @Prop() userToxicity!: UserToxicityFields
}
